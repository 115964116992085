import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams
import DashboardLayout from "../components/DashboardLayout";
import { Loader } from "../components/Loader/Loader";
import DropdownMenu from "../components/Menus/DropdownMenu";
import { Table } from "../components/Table/Table";
import { getUserTechnicians, useUser } from "../hooks/useAuth";
import { useFetchHardwares } from "../hooks/useHardware";
import { useSearchJobs } from "../hooks/useJobs";
import { FILTER_LIST, PAGE_LIMIT } from "../utils/contants";
import AddFilterDropdown from "../components/FilterComponents/AddFilter";
import AppliedFilters from "../components/FilterComponents/AppliedFilters";

const initialDateRange = { startDate: "", endDate: "" };

const SearchFilters = ({
  isFilterApplied,
  clearFilters,
  input,
  resetDropdownRef,
  setInput,
  handleKeyPress,
  hardwareDropdownOptions,
  technicianDropdownOptions,
  selectedFilter,
  setSelectedFilter,
  dateRange,
  setDateRange,
  appliedSelectedHardwareOptions,
  appliedSelectedTechnicianOptions,
  handleApplyFilters,
}) => {

  return (
    <div className="flex flex-wrap py-2 gap-3 items-center justify-end">

      <div className="flex items-center justify-center">
        {isFilterApplied && (
          <button
            onClick={clearFilters}
            className="border-red-500 text-sm text-red-500 flex p-2 cursor-pointer rounded-lg border border-dashed"
          >
            Clear Filters
          </button>
        )}
      </div>
      <div className="flex max-w-[260px] items-center border h-fit border-gray-300 px-2 rounded-md focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500">
        <MagnifyingGlassIcon className="w-6 h-6 text-gray-400" />
        <input
          type="text"
          className="py-2 px-2 focus-visible:outline-none w-full"
          placeholder="Search"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
      
      
      <div>
        <AddFilterDropdown
          hardwareDropdownOptions={hardwareDropdownOptions}
          technicianDropdownOptions={technicianDropdownOptions}
          onApply={handleApplyFilters} // Pass the handler
          initialHardwareSelections={appliedSelectedHardwareOptions}
          initialTechnicianSelections={appliedSelectedTechnicianOptions}
        />
      </div>
      <div className="flex max-w-[240px]">
        <DropdownMenu
          label=""
          dropdownOptions={FILTER_LIST}
          selector={selectedFilter}
          setSelector={setSelectedFilter}
          arrowDirectionUp={false}
          calender={true}
          classNames="w-[200px]"
          dateRange={dateRange}
          updateDateRange={setDateRange}
          onReset={(resetFunction) => {
            resetDropdownRef.current = resetFunction;
          }}
        />
      </div>
    </div>
  );
};

export default function Search() {
  const { data: hardwareData } = useFetchHardwares();
  const [searchParams, setSearchParams] = useSearchParams(); // Initialize searchParams
  const [searchQuery, setSearchQuery] = useState("");
  const [input, setInput] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(null);


  const [appliedSelectedHardwareOptions, setAppliedSelectedHardwareOptions] = useState(["All Hardwares"]);
  const [appliedSelectedTechnicianOptions, setAppliedSelectedTechnicianOptions] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const { data: userData } = useUser();

  const { data, isLoading, isError, error, refetch, isFetching } = useSearchJobs(
    {
      page: pageNumber,
      search: searchQuery,
      user: userData?.user,
      role: userData?.user?.role,
      hwid: appliedSelectedHardwareOptions.includes("All Hardwares")
        ? ""
        : hardwareData
            ?.filter((hw) => appliedSelectedHardwareOptions.includes(hw.name))
            .map((hw) => hw._id) || [],
      selectedFilter: selectedFilter,
      technicianId: appliedSelectedTechnicianOptions.map(
        (techName) => technicians.find((tech) => tech.technicianId.username === techName)?._id
      ).filter(id => id), // Remove undefined
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    },
    {
      enabled: Boolean(userData),
    }
  );

  // Ref to prevent setting state on initial load
  const isInitialMount = useRef(true);

  // Initialize state from query parameters on mount
  useEffect(() => {
    const initSearch = searchParams.get("search") || "";
    const initHardware = searchParams.get("hardware")
      ? searchParams.get("hardware").split(",")
      : ["All Hardwares"];
    const initTechnician = searchParams.get("technician")
      ? searchParams.get("technician").split(",")
      : [];
    const initFilter = searchParams.get("filter")

    const initStartDate = searchParams.get("startDate") || "";
    const initEndDate = searchParams.get("endDate") || "";
    const initPage = parseInt(searchParams.get("page")) || 0;

    setSearchQuery(initSearch);
    setInput(initSearch);
    setAppliedSelectedHardwareOptions(initHardware.length > 0 ? initHardware : ["All Hardwares"]);
    setAppliedSelectedTechnicianOptions(initTechnician);
    setSelectedFilter(initFilter);
    setDateRange({
      startDate: initStartDate,
      endDate: initEndDate,
    });
    setPageNumber(initPage);
  }, []); // Run only once on mount

  // Update query parameters when filters change
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const params = {};

    if (searchQuery) params.search = searchQuery;
    if (
      appliedSelectedHardwareOptions.length > 0 &&
      !(appliedSelectedHardwareOptions.length === 1 && appliedSelectedHardwareOptions.includes("All Hardwares"))
    ) {
      params.hardware = appliedSelectedHardwareOptions.join(",");
    }
    if (appliedSelectedTechnicianOptions.length > 0) {
      params.technician = appliedSelectedTechnicianOptions.join(",");
    }
    if (selectedFilter && selectedFilter !== "Last 30 Days") {
      params.filter = selectedFilter;
    }
    if (dateRange.startDate && dateRange.endDate) {
      params.startDate = dateRange.startDate;
      params.endDate = dateRange.endDate;
    }
    if (pageNumber !== 0) {
      params.page = pageNumber;
    }

    setSearchParams(params);
  }, [
    searchQuery,
    appliedSelectedHardwareOptions,
    appliedSelectedTechnicianOptions,
    selectedFilter,
    dateRange,
    pageNumber,
    setSearchParams,
  ]);

  // Reset page number when applied filters or search query change
  useEffect(() => {
    setPageNumber(0);
  }, [searchQuery, selectedFilter, appliedSelectedHardwareOptions, appliedSelectedTechnicianOptions, dateRange]);

  const resetDropdownRef = useRef(null);

  const isFilterApplied = useMemo(() => {
    const isDateRangeSelected = Boolean(dateRange.startDate && dateRange.endDate);
    
    const isHardwareSelected =
      appliedSelectedHardwareOptions.length > 0 &&
      !(appliedSelectedHardwareOptions.length === 1 && appliedSelectedHardwareOptions.includes("All Hardwares"));
  
    const isTechnicianSelected = appliedSelectedTechnicianOptions.length > 0;
      const isSearchApplied = searchQuery.trim().length > 0;
      return isDateRangeSelected || isHardwareSelected || isTechnicianSelected || isSearchApplied;
  }, [searchQuery, dateRange, appliedSelectedHardwareOptions, appliedSelectedTechnicianOptions]);
  
  const hardwareTechFilterApplied = useMemo(() => {
    const isHardwareSelected =
      appliedSelectedHardwareOptions.length > 0 &&
      !(appliedSelectedHardwareOptions.length === 1 && appliedSelectedHardwareOptions.includes("All Hardwares"));
    const isTechnicianSelected = appliedSelectedTechnicianOptions.length > 0;

    return isHardwareSelected || isTechnicianSelected;
  }, [appliedSelectedHardwareOptions, appliedSelectedTechnicianOptions]);

  useEffect(() => {
    getAllTechnicians();
  }, [userData]);

  const getAllTechnicians = async () => {
    if (userData?.user?._id) {
      const { data } = await getUserTechnicians(userData.user._id);
      setTechnicians(data);
    }
  };

  const pageCount = useMemo(() => {
    let count = data && data.jobCount / PAGE_LIMIT;
    return count < 1 ? Math.ceil(0) : Math.ceil(count);
  }, [data]);

  const handleClickSearch = useCallback(() => {
    setSearchQuery(input);
  }, [input]);

  const handlePageClick = useCallback((event) => {
    setPageNumber(event.selected);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleClickSearch();
    }
  };

  const clearFilters = useCallback(() => {
    setAppliedSelectedHardwareOptions(["All Hardwares"]);
    setAppliedSelectedTechnicianOptions([]);
    setSearchQuery("");
    setInput("");
    setSelectedFilter(null);
    setDateRange(initialDateRange);
    setPageNumber(0); // Reset to first page
    if (resetDropdownRef.current) {
      resetDropdownRef.current();
    }
  }, []);

  const handleApplyFilters = useCallback((hardware, technicians) => {
    setAppliedSelectedHardwareOptions(hardware);
    setAppliedSelectedTechnicianOptions(technicians);
  }, []);

  const getJobsHeader = () => {
    return [
      { name: "Address", ref: "address" },
      { name: "Chemicals", ref: "chemicals" },
      { name: "Status", ref: "status" },
      { name: "Technician", ref: "technician" },
      { name: "Date", ref: "date" },
    ];
  };

  const jobsData = () => {
    if (!data?.jobs || data.jobs.length === 0) return [];

    return data.jobs.map((job) => ({
      address: (
        <div className="flex items-center">
          <a
            className="hover:text-blue-500"
            href={`./job-details/${job?._id}`}
          >
            <p className="text-sm">{job?.address?.address}</p>
          </a>
        </div>
      ),
      Chemicals: (
        <div>
          {job.chemicals.map((chemical) => (
            <p key={chemical._id} className="text-sm">
              {chemical.chemicalName}
            </p>
          ))}
        </div>
      ),
      status: (
        <span
          className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 
                  ${
                    job.active === true
                      ? "bg-blue-100 text-blue-800"
                      : "bg-green-100 text-green-800"
                  }`}
        >
          {job.active === true ? "In-Progress" : "Completed"}
        </span>
      ),
      technician:
        (job?.user ? job?.user[0]?.username : job?.technician[0]?.username) ??
        job?.user?.username,
      date: dayjs(job.startTime).format("DD MMM YYYY"),
      actions: <></>,
    }));
  };

  const hardwareDropdownOptions = hardwareData?.map(
    (hardware) => hardware.name
  ) || [];

  const technicianDropdownOptions = technicians?.map(
    (technician) => technician?.technicianId?.username
  ) || [];

  const tabledata = {
    tabledata: jobsData(),
    headers: getJobsHeader(),
  };

  return (
    <DashboardLayout>
      <section className="mt-10 px-10">
        <div className="px-4 sm:px-6 lg:px-8 w-full ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-medium text-gray-900">Search</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the jobs in your account including their address,
                status, chemicals and technician.
              </p>
            </div>
          </div>
          <SearchFilters
            clearFilters={clearFilters}
            dateRange={dateRange}
            handleKeyPress={handleKeyPress}
            hardwareDropdownOptions={hardwareDropdownOptions}
            isFilterApplied={isFilterApplied}
            selectedFilter={selectedFilter}
            setDateRange={setDateRange}
            setSelectedFilter={setSelectedFilter}
            technicianDropdownOptions={technicianDropdownOptions}
            setInput={setInput}
            input={input}
            appliedSelectedHardwareOptions={appliedSelectedHardwareOptions}
            appliedSelectedTechnicianOptions={appliedSelectedTechnicianOptions}
            setAppliedSelectedHardwareOptions={setAppliedSelectedHardwareOptions}
            setAppliedSelectedTechnicianOptions={setAppliedSelectedTechnicianOptions}
            resetDropdownRef={resetDropdownRef}
            handleApplyFilters={handleApplyFilters} // Pass the handler
          />

           {/* Applied Filters Section */}
           {hardwareTechFilterApplied && (
            <AppliedFilters
              hardwareDropdownOptions={hardwareDropdownOptions}
              technicianDropdownOptions={technicianDropdownOptions}
              appliedSelectedHardwareOptions={appliedSelectedHardwareOptions}
              appliedSelectedTechnicianOptions={appliedSelectedTechnicianOptions}
              setAppliedSelectedHardwareOptions={setAppliedSelectedHardwareOptions}
              setAppliedSelectedTechnicianOptions={setAppliedSelectedTechnicianOptions}
            />
          )}
        </div>
        <div className="px-4 sm:px-6 lg:px-8">
          {isLoading || isFetching ? <Loader /> : <Table {...tabledata} />}
          <div className="mt-3 float-right">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
              forcePage={pageNumber}
            />
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
}
