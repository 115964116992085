import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const TimeDuration = (filter) => {
    let startDate;
    let endDate = dayjs.utc().local().format();

    switch (filter) {
        case "Today":
            startDate = dayjs.utc().local().startOf('day').format();
            break;
        case "Yesterday":
            startDate = dayjs.utc().local().subtract(1, 'days').startOf('day').toISOString();
            endDate = dayjs.utc().local().subtract(1, 'days').endOf('day').toISOString();
            break;
        case "This Week":
            startDate = dayjs.utc().local().startOf('week').format();
            break;
        case "Previous Week":
            startDate = dayjs.utc().local().subtract(1, 'week').startOf('week').format();
            endDate = dayjs.utc().local().subtract(1, 'week').endOf('week').format();
            break;
        case "Last 7 Days":
            startDate = dayjs.utc().local().subtract(7, 'days').startOf('day').format();
            break;
        case "This Month":
            startDate = dayjs.utc().local().startOf('month').format();
            break;
        case "Previous Month":
            startDate = dayjs.utc().local().subtract(1, 'month').startOf('month').format();
            endDate = dayjs.utc().local().subtract(1, 'month').endOf('month').format();
            break;
        case "Last 30 Days":
            startDate = dayjs.utc().local().subtract(30, 'days').startOf('day').format();
            break;
        case "Last 60 Days":
            startDate = dayjs.utc().local().subtract(60, 'days').startOf('day').format();
            break;
        case "Last Year":
            startDate = dayjs.utc().local().subtract(1, 'year').startOf('year').format();
            break;
        default:
            startDate = dayjs("2020-01-01T10:00:00").toISOString();
            break;
    }

    return { startDate, endDate };
};


export const removeTimeZoneFromDate = (date) => {
    if (!date || !date.length) return;
    const formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm');
    return formattedDate;
};