import { Combobox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

const SearchableDropdown = ({
  options = [],
  selectedOption,
  setSelectedOption,
  placeholder = "",
}) => {
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option?.toLowerCase().includes(query.toLowerCase())
        );

  return (
    <Combobox as="div" value={selectedOption} onChange={setSelectedOption}>
      <div className="relative w-full max-w-[200px]">
        <Combobox.Input
          className="w-full border h-[2.6rem] border-gray-300 rounded-lg py-2 px-3 text-sm leading-5 text-gray-900"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(option) => option || ""}
          placeholder={placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>
        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
            {filteredOptions.map((option, index) => (
              <Combobox.Option
                key={index}
                value={option}
                className={({ active }) =>
                  `cursor-default select-none relative py-2 pl-3 pr-9 mx-1 ${
                    active
                      ? "rounded-md mx-1 bg-blue-50 text-blue-500 hover:stroke-blue-500 hover:fill-blue-500"
                      : "text-gray-900"
                  }`
                }
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-semibold" : "font-normal"
                      }`}
                    >
                      {option}
                    </span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export { SearchableDropdown };
