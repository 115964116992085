import * as React from 'react';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../components/Button/PrimaryButton';
import DashboardLayout from '../components/DashboardLayout';
import { DeletePopup } from '../components/Popups/DeletePopup';
import { ErrorAlert } from '../components/Popups/ErrorAlert';
import { PopupBox } from '../components/Popups/PopupBox';
import { Table } from '../components/Table/Table.jsx';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import { SecondaryButton } from './../components/Button/SecondaryButton.jsx';
import { useCreateChemical, useDeleteChemical, useFetchChemicals, useUpdateChemical } from '../hooks/useChemicals';
import { removePTags } from '../utils/helpers.js';

export default function Dashboard() {
  const { mutate: deleteChemical } = useDeleteChemical();
  const { mutate: addChemical } = useCreateChemical();
  const { mutate: updateChemical } = useUpdateChemical();
  const { isLoading, data, error, isError } = useFetchChemicals();

  // chemical states
  const [errors, setErrors] = useState([]);
  const [chemicalModal, setChemicalModal] = useState(false);
  const [chemicalName, setchemicalName] = useState('');
  const [chemicalContent, setchemicalContent] = useState('');
  const [chemicalConcentration, setChemicalConcentration] = useState('');
  const [chemicalId, setChemicalId] = useState(null);

  const dropdownOptions = [
    { name: 'Grams per gallon' },
    { name: 'Ounces per gallon' },
    { name: 'Mililiter per gallon' },
  ];
  const [measurementUnit, setMeasurementUnit] = useState(dropdownOptions[0].name);

  // delete state
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    setErrors([]);
  }, [chemicalName, chemicalContent, chemicalConcentration]);

  function handleSubmit() {
    const numericConcentration = parseFloat(chemicalConcentration);

    // Validation logic
    if (!chemicalName) {
      setErrors(['Chemical name is not defined.']);
      return;
    } else if (!chemicalContent) {
      setErrors(['Chemical description is not defined.']);
      return;
    } else if (!chemicalConcentration) {
      setErrors(['Chemical concentration is not defined.']);
      return;
    } else if (isNaN(numericConcentration) || numericConcentration <= 0) {
      setErrors(['Chemical concentration must be a valid number greater than 0.']);
      return;
    } else {
      if (chemicalId) {
        updateChemical({
          sprayId: chemicalId,
          chemical: chemicalName,
          description: chemicalContent,
          concentration: numericConcentration, // Use parsed float value
          selectedMeasurement: measurementUnit,
        });
      } else {
        addChemical({
          chemical: chemicalName,
          description: chemicalContent,
          concentration: numericConcentration, // Use parsed float value
          selectedMeasurement: measurementUnit,
        });
      }
      setChemicalModal(false);
      setchemicalName('');
      setchemicalContent('');
      setChemicalConcentration('');
      setMeasurementUnit(dropdownOptions[0].name);
    }
  }

  const handleEditClick = (card) => {
    setChemicalId(card._id);
    setchemicalName(card.chemicalName);
    setChemicalConcentration(card.concentration);
    setchemicalContent(card.description);
    setMeasurementUnit(card.measurement);
    setChemicalModal(true);
  };

  const handleCreateClick = () => {
    setChemicalId(null);
    setchemicalName('');
    setchemicalContent('');
    setChemicalConcentration('');
    setMeasurementUnit(dropdownOptions[0].name);
    setChemicalModal(true);
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const deleteSelected = (e) => {
    if (e) {
      e.preventDefault(); // Optional: Prevent default behavior
    }
    deleteChemical(deleteId);
    setDeleteId(null);
    setDeleteModal(false);
  };

  const getChemicalsHeader = () => {
    return [
      { name: 'Name', ref: 'name' },
      { name: 'Description', ref: 'description' },
      { name: 'Quantity', ref: 'quantity' },
      { name: 'Edit', ref: 'edit' },
      { name: 'Delete', ref: 'delete' },
    ];
  };

  const getChemicalsData = () => {
    if (data?.length <= 0) return [];
    const currentData = [];
    data?.forEach((chemical) => {
      currentData.push({
        name: chemical.chemicalName,
        description: removePTags(chemical.description),
        quantity: `${chemical.concentration}${chemical.measurement}`,
        edit: (
          <div className='mt-3 flex justify-center items-center'>
            <SecondaryButton
              label='Edit'
              icon={<PencilIcon className='w-4 text-gray-500' />}
              onClick={() => handleEditClick(chemical)}
            />
          </div>
        ),
        delete: (
          <div className='mt-3 flex gap-x-3 md:gap-x-4 justify-start items-center'>
            <SecondaryButton
              label='Delete'
              icon={<TrashIcon className='w-4 text-gray-500' />}
              onClick={() => handleDeleteClick(chemical._id)}
            />
          </div>
        ),
        actions: <></>,
      });
    });
    return currentData;
  };

  const tabledata = {
    tabledata: getChemicalsData(),
    headers: getChemicalsHeader(),
  };

  return (
    <DashboardLayout>
      {isLoading && <p>...Loading</p>}
      {isError && <ErrorAlert errorMessages={error.message} />}

      <div className='flex justify-end mr-4 mt-2'>
        <PrimaryButton label='Create new chemical' onClickEvent={handleCreateClick} displayPlus />
      </div>

      <PopupBox
        modal={chemicalModal}
        handleClose={() => setChemicalModal(false)}
        title={chemicalId ? 'Update chemical' : 'Add new chemical'}
        setModal={setChemicalModal}
        name={chemicalName}
        setName={setchemicalName}
        content={chemicalContent}
        setContent={setchemicalContent}
        concentration={chemicalConcentration}
        setConcentration={setChemicalConcentration}
        dropdown={measurementUnit}
        setDropdown={setMeasurementUnit}
        measurementLabel='measurement'
        dropdownOptions={dropdownOptions}
        dropdownLabel='unit'
        errors={errors}
        handleSubmit={handleSubmit}
      />

      <DeletePopup
        onDelete={() => deleteSelected()}
        label='Delete Chemical'
        desc='Are you sure you want to delete this chemical?'
        open={deleteModal}
        setOpen={setDeleteModal}
      />

      <div className='m-5'>
        <Table {...tabledata} />
      </div>
    </DashboardLayout>
  );
}
