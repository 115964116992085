import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon, WrenchIcon, UserIcon } from "@heroicons/react/24/outline";

const AddFilterDropdown = ({
  onApply, // Function to handle applying the selected filters
  hardwareDropdownOptions,
  technicianDropdownOptions,
  initialHardwareSelections,
  initialTechnicianSelections,
}) => {
  const mainOptions = ["Hardware", "Technicians"];

  const mainOptionIcons = {
    Hardware: <WrenchIcon className="h-6 w-6 mr-2 text-black" />,
    Technicians: <UserIcon className="h-6 w-6 mr-2 text-black" />,
  };

  const [activeSubFilters, setActiveSubFilters] = useState({
    Hardware: false,
    Technicians: false,
  });

  // Temporary selections
  const [tempSelectedHardware, setTempSelectedHardware] = useState(
    initialHardwareSelections
  );
  const [tempSelectedTechnician, setTempSelectedTechnician] = useState(
    initialTechnicianSelections
  );

  // Handle main option toggle
  const handleMainOptionClick = (option, event) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveSubFilters((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  // Handle sub-option toggle
  const handleSubOptionClick = (option, type) => {
    if (type === "Hardware") {
      if (option === "All Hardwares") {
        setTempSelectedHardware(["All Hardwares"]);
      } else {
        setTempSelectedHardware((prev) => {
          const isSelected = prev.includes(option);
          if (isSelected) {
            // Deselect
            const newSelections = prev.filter((item) => item !== option);
            return newSelections.length === 0 ? ["All Hardwares"] : newSelections;
          } else {
            // Select
            return ["All Hardwares", ...prev.filter((item) => item !== "All Hardwares"), option];
          }
        });
      }
    } else if (type === "Technicians") {
      setTempSelectedTechnician((prev) => {
        const isSelected = prev.includes(option);
        if (isSelected) {
          // Deselect
          return prev.filter((item) => item !== option);
        } else {
          // Select
          return [...prev, option];
        }
      });
    }
  };

  // Handle Apply button click
  const handleApply = () => {
    onApply(tempSelectedHardware, tempSelectedTechnician);
    setActiveSubFilters({
      Hardware: false,
      Technicians: false,
    });
  };

  // Reset temporary selections when initial selections change
  useEffect(() => {
    setTempSelectedHardware(initialHardwareSelections);
  }, [initialHardwareSelections]);

  useEffect(() => {
    setTempSelectedTechnician(initialTechnicianSelections);
  }, [initialTechnicianSelections]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center items-center w-full rounded-md border border-dashed border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
          Add Filter
          <PlusIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute left-0 mt-2 w-60 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none z-50"
        >
          <div className="py-2 px-2">
            {mainOptions.map((option) => (
              <div key={option} className="mb-2">
                <button
                  onClick={(event) => handleMainOptionClick(option, event)}
                  className="w-full flex items-center px-4 py-2 text-sm text-gray-800 hover:bg-gray-100 rounded-md"
                  type="button"
                >
                  {mainOptionIcons[option]}
                  {option}
                </button>
                {activeSubFilters[option] && (
                  <div className="pl-8 py-2 max-h-48 overflow-y-auto">
                    {(option === "Hardware"
                      ? hardwareDropdownOptions
                      : technicianDropdownOptions
                    ).map((subOption) => (
                      <div
                        key={subOption}
                        className="flex items-center mb-1"
                      >
                        <input
                          id={`sub-option-${option}-${subOption}`}
                          type="checkbox"
                          checked={
                            option === "Hardware"
                              ? tempSelectedHardware.includes(subOption)
                              : tempSelectedTechnician.includes(subOption)
                          }
                          onChange={() => handleSubOptionClick(subOption, option)}
                          className="mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                          htmlFor={`sub-option-${option}-${subOption}`}
                          className="text-gray-700 text-sm"
                        >
                          {subOption}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="px-4 py-2">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={handleApply} // No need to pass Menu.close
                  className={`${
                    active ? "bg-blue-700" : "bg-blue-600"
                  } w-full text-white px-3 py-2 rounded-md focus:outline-none`}
                  type="button"
                >
                  Apply
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AddFilterDropdown;
