import React from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../features/modalSlice';

const ModalVideoComponent = () => {
  const dispatch = useDispatch();
  const { isOpen, currentVideoId } = useSelector((state) => state.modal);

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <ModalVideo
      channel="youtube"
      isOpen={isOpen}
      videoId={currentVideoId}
      onClose={handleClose}
    />
  );
};

export default ModalVideoComponent;
