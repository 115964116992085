import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/outline";

const AppliedFilters = ({
  hardwareDropdownOptions,
  technicianDropdownOptions,
  appliedSelectedHardwareOptions,
  appliedSelectedTechnicianOptions,
  setAppliedSelectedHardwareOptions,
  setAppliedSelectedTechnicianOptions,
}) => {
  // Determine if Hardware filters are active (excluding "All Hardwares")
  const isHardwareSelected =
    appliedSelectedHardwareOptions.length > 0 &&
    !(appliedSelectedHardwareOptions.length === 1 && appliedSelectedHardwareOptions.includes("All Hardwares"));

  // Determine if Technician filters are active
  const isTechnicianSelected = appliedSelectedTechnicianOptions.length > 0;

  // Handler to toggle hardware selection
  const toggleHardware = (option) => {
    if (option === "All Hardwares") {
      setAppliedSelectedHardwareOptions(["All Hardwares"]);
    } else {
      if (appliedSelectedHardwareOptions.includes(option)) {
        const newSelection = appliedSelectedHardwareOptions.filter(
          (hw) => hw !== option
        );
        setAppliedSelectedHardwareOptions(
          newSelection.length > 0 ? newSelection : ["All Hardwares"]
        );
      } else {
        setAppliedSelectedHardwareOptions([
          ...appliedSelectedHardwareOptions.filter((hw) => hw !== "All Hardwares"),
          option,
        ]);
      }
    }
  };

  // Handler to toggle technician selection
  const toggleTechnician = (option) => {
    if (appliedSelectedTechnicianOptions.includes(option)) {
      const newSelection = appliedSelectedTechnicianOptions.filter(
        (tech) => tech !== option
      );
      setAppliedSelectedTechnicianOptions(newSelection);
    } else {
      setAppliedSelectedTechnicianOptions([...appliedSelectedTechnicianOptions, option]);
    }
  };

  // Handler to select all hardware options
  const selectAllHardware = () => {
    setAppliedSelectedHardwareOptions(["All Hardwares", ...hardwareDropdownOptions]);
  };

  // Handler to clear all hardware selections
  const clearAllHardware = () => {
    setAppliedSelectedHardwareOptions(["All Hardwares"]);
  };

  // Handler to select all technician options
  const selectAllTechnicians = () => {
    setAppliedSelectedTechnicianOptions([...technicianDropdownOptions]);
  };

  // Handler to clear all technician selections
  const clearAllTechnicians = () => {
    setAppliedSelectedTechnicianOptions([]);
  };

  return (
    <div className="flex flex-wrap gap-4 mb-6">
      {/* Hardware Filters Dropdown */}
      {isHardwareSelected && (
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center items-center px-4 py-2 bg-blue-100 text-blue-800 text-sm font-medium rounded-md hover:bg-blue-200 focus:outline-none">
              Hardware
              <ChevronDownIcon className="w-5 h-5 ml-2" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute mt-2 w-56 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none z-50">
              <div className="px-4 py-2">
                <button
                  onClick={selectAllHardware}
                  className="flex items-center w-full px-2 py-1 text-sm text-blue-600 hover:bg-gray-100 rounded"
                >
                  <CheckIcon className="w-4 h-4 mr-2" />
                  Select All
                </button>
                <button
                  onClick={clearAllHardware}
                  className="flex items-center w-full px-2 py-1 mt-1 text-sm text-red-600 hover:bg-gray-100 rounded"
                >
                  <CheckIcon className="w-4 h-4 mr-2" />
                  Clear All
                </button>
              </div>
              <div className="py-1 px-2 min-h-0 max-h-40 overflow-auto">
                {hardwareDropdownOptions.map((option) => (
                  <Menu.Item key={option}>
                    {({ active }) => (
                      <button
                        onClick={() => toggleHardware(option)}
                        className={`flex items-center w-full px-2 py-1 text-sm ${
                          active ? "bg-gray-100" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={
                            appliedSelectedHardwareOptions.includes(option) ||
                            (appliedSelectedHardwareOptions.includes("All Hardwares") &&
                              option === "All Hardwares")
                          }
                          readOnly
                          className="mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded"
                        />
                        {option}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}

      {/* Technician Filters Dropdown */}
      {isTechnicianSelected && (
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center items-center px-4 py-2 bg-green-100 text-green-800 text-sm font-medium rounded-md hover:bg-green-200 focus:outline-none">
              Technician
              <ChevronDownIcon className="w-5 h-5 ml-2" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute mt-2 w-56 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none z-50">
              <div className="px-4 py-2">
                <button
                  onClick={selectAllTechnicians}
                  className="flex items-center w-full px-2 py-1 text-sm text-green-600 hover:bg-gray-100 rounded"
                >
                  <CheckIcon className="w-4 h-4 mr-2" />
                  Select All
                </button>
                <button
                  onClick={clearAllTechnicians}
                  className="flex items-center w-full px-2 py-1 mt-1 text-sm text-red-600 hover:bg-gray-100 rounded"
                >
                  <CheckIcon className="w-4 h-4 mr-2" />
                  Clear All
                </button>
              </div>
              <div className="py-1 px-2 min-h-0 max-h-40">
                {technicianDropdownOptions.map((option) => (
                  <Menu.Item key={option}>
                    {({ active }) => (
                      <button
                        onClick={() => toggleTechnician(option)}
                        className={`flex items-center w-full px-2 py-1 text-sm ${
                          active ? "bg-gray-100" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={appliedSelectedTechnicianOptions.includes(option)}
                          readOnly
                          className="mr-2 h-4 w-4 text-green-600 border-gray-300 rounded"
                        />
                        {option}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default AppliedFilters;
