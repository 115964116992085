import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    step: 1,
    chemicals: [],
    hardwares: [],
    address: '',
    mapCenter: {
        lat: 0,
        lng: 0
    }
}

export const jobDetailsSlice = createSlice({
    name: 'jobDetails',
    initialState,
    reducers: {
        setMapCenter: (state, action) => {
            state.mapCenter = {
                lat: action.payload.lat,
                lng: action.payload.lng
            }
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setAddress: (state, action) => {
            state.address = action.payload
        },
        setChemicals: (state, action) => {
            state.chemicals = action.payload;
        }
        , setHardwares: (state, action) => {
            state.hardwares = action.payload;
        }
        ,
        flushjobDetails: (state) => {
            state.step = initialState.step
            state.chemicals = initialState.chemicals
            state.address = initialState.address
            state.mapCenter = initialState.mapCenter
        }
    }
})

export const { setMapCenter, setStep, setAddress, setChemicals, setHardwares, setChemical, setHardware, setDefaultHardware, flushjobDetails } = jobDetailsSlice.actions

export default jobDetailsSlice.reducer