import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DashboardLayout from "../components/DashboardLayout";
import { Loader } from "../components/Loader/Loader";
import MarkerDetails from "../components/MapView/MarkerDetails";
import DropdownMenu from "../components/Menus/DropdownMenu";
import { ErrorAlert } from "../components/Popups/ErrorAlert";
import { SearchableDropdown } from "../components/SearchableDropdown/SearchableDropdown";
import { useUser } from "../hooks/useAuth";
import { useFetchHardwares } from "../hooks/useHardware";
import { useGetAllUniqueJobs } from "../hooks/useJobs";
import { FILTER_LIST } from "../utils/contants";
import markerPin from "./../assets/pin1.png";
import { useSearchParams } from "react-router-dom"; // Import for handling query params
import format from "date-fns/format";
const center = {
  lat: 42.06012414889974,
  lng: -91.63162586405998,
};

// Function to get start of the day (00:00:00)
const getStartOfDay = (date) => {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  return start;
};

// Function to get end of the day (23:59:59)
const getEndOfDay = (date) => {
  const end = new Date(date);
  end.setHours(23, 59, 59, 999);
  return end;
};

// Set initial date range with today's start and end times
const initialDateRange = {
  startDate: format(getStartOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSS"), // Start of today
  endDate: format(getEndOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSS"),    // End of today
};

const containerStyle = {
  height: "600px",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px #8888",
};

function MapView() {
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage query params
  const [mapWidth, setMapWidth] = useState(() =>
    window.innerWidth < 769 ? `${window.innerWidth - 40}px` : "100%"
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const { data: hardwareData } = useFetchHardwares();
  const [selectedOption, setSelectedOption] = useState(
    searchParams.get("hardware") || "All Hardwares" // Initialize from query params
  );
  const [selectedHardwareId, setSelectedHardwareId] = useState(
    searchParams.get("hardwareId") || null // Initialize from query params if exists
  );
  const [step, setStep] = useState(1);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const { data: userData } = useUser();
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(8);
  const [propertyId, setPropertyId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const isFilterApplied = useMemo(() => {
    const isDateRangeSelected =
      selectedOption === "All Hardwares" ? false : Boolean(selectedOption);
    const isHardwareIdSelected = Boolean(selectedHardwareId);

    return (
      isDateRangeSelected ||
      isHardwareIdSelected ||
      dateRange.startDate ||
      dateRange.endDate
    );
  }, [selectedOption, selectedHardwareId, dateRange]);

  const resetDropdownRef = useRef(null);
  const onLoad = useCallback((map) => setMap(map), []);
  const onUnmount = useCallback(() => setMap(null), []);

  useEffect(() => {
    if (selectedOption && hardwareData) {
      if (selectedOption === "All Hardwares") {
        setSelectedHardwareId(null);
        searchParams.delete("hardware");
        searchParams.delete("hardwareId");
      } else {
        const hardware = hardwareData.find((h) => h.name === selectedOption);
        if (hardware) {
          setSelectedHardwareId(hardware._id);
          searchParams.set("hardware", selectedOption);
          searchParams.set("hardwareId", hardware._id);
        }
      }
      setSearchParams(searchParams); // Update the query params
    }
  }, [selectedOption, hardwareData, searchParams, setSearchParams]);

  const { data, isError, error, isLoading } = useGetAllUniqueJobs({
    user: userData?.user?._id,
    technician:
      userData?.user?.role === "technician" ? userData?.user?._id : null,
    hardwareId: selectedHardwareId,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  useEffect(() => {
    if (!propertyId) {
      setSelectedAddress(null);
      return;
    }

    const selected = data?.find((address) => address?._id === propertyId);
    if (selected) {
      setSelectedAddress(selected);
      setZoom(20);
      map?.panTo(selected.mapCenter);
    }

    if (step === 1) {
      setZoom(8);
      setSelectedAddress(undefined);
    }
  }, [propertyId, data, map, step]);

  const clearFilters = useCallback(() => {
    setSelectedOption("All Hardwares");
    setSelectedHardwareId("");
    localStorage.removeItem("dateRange");
    setDateRange(initialDateRange);
    if (resetDropdownRef.current) {
      resetDropdownRef.current();
    }
    setStep(1);
  }, []);

  const onMarkerClick = (jobAddressId) => {
    setStep(2);
    setPropertyId(jobAddressId);
  };

  const DisplayAddress = () => {
    if (!selectedAddress)
      return data.map((jobAddress) => (
        <Marker
          key={jobAddress._id}
          position={jobAddress.mapCenter}
          onClick={() => onMarkerClick(jobAddress._id)}
          icon={{
            url: markerPin,
            scaledSize: new window.google.maps.Size(20, 20),
            origin: new window.google.maps.Point(0, 0),
          }}
        />
      ));

    return (
      <Marker
        key={selectedAddress._id}
        position={selectedAddress.mapCenter}
        onClick={() => onMarkerClick(selectedAddress._id)}
        icon={{
          url: markerPin,
          scaledSize: new window.google.maps.Size(30, 30),
          origin: new window.google.maps.Point(0, 0),
        }}
      />
    );
  };

  if (isLoading || data === undefined) return <Loader />;
  if (isError) return <ErrorAlert errorMessages={error.message} />;

  return isLoaded ? (
    <DashboardLayout>
      <div className="w-full block p-5">
        <div className="flex flex-wrap items-center justify-end mb-3 gap-2 px-0 w-">
          <div className="flex mx-3 items-center justify-center">
            {isFilterApplied && (
              <button
                onClick={clearFilters}
                className="border-red-500 text-sm text-red-500 flex p-2 cursor-pointer rounded-lg border border-dashed"
              >
                Clear Filters
              </button>
            )}
          </div>
          <div className="flex h-full">
            <SearchableDropdown
              options={["All Hardwares", ...hardwareData?.map((hardware) => hardware.name)]}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              placeholder="Search Hardwares"
            />
          </div>
          <div className="flex mw-full">
            <DropdownMenu
              label=""
              dropdownOptions={FILTER_LIST}
              selector={selectedOption}
              setSelector={setSelectedOption}
              arrowDirectionUp={false}
              setStep={setStep}
              calender={true}
              dateRange={dateRange}
              updateDateRange={setDateRange}
              dateRangeValue={dateRange}
              classNames="w-[190px]"
              onReset={(resetFunction) => {
                resetDropdownRef.current = resetFunction;
              }}
            />
          </div>
        </div>
        <div className="flex md:flex-row flex-col gap-x-4">
          <GoogleMap
            mapContainerStyle={{ ...containerStyle, width: mapWidth }}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={zoom}
            options={{
              mapTypeId: "hybrid",
              streetViewControl: false,
              mapTypeControl: false,
              rotateControl: false,
            }}
          >
            <DisplayAddress />
          </GoogleMap>

          <MarkerDetails
            step={step}
            setStep={setStep}
            job={data}
            propertyId={propertyId}
            setPropertyId={setPropertyId}
          />
        </div>
      </div>
    </DashboardLayout>
  ) : null;
}

export default React.memo(MapView);
