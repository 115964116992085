import {
  BeakerIcon,
  BoltIcon,
  MapPinIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import { Table } from "../components/Table/Table";
import { useUser } from "../hooks/useAuth";
import { useGetLatestJobs } from "../hooks/useJobs";
import { PrimaryButton } from "../components/Button/PrimaryButton";
const userActions = [
  {
    icon: BeakerIcon,
    name: "Chemicals",
    href: "/chemicals",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    description: "This is the chemical data",
  },
  {
    icon: MapPinIcon,
    name: "Map",
    href: "/mapview",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    description: "This is the map",
  },
  {
    icon: BoltIcon,
    name: "Start Job",
    href: "/start",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    description: "Click here to start a job",
  },

];

const technicianActions = [
  {
    icon: MapPinIcon,
    name: "Map",
    href: "/mapview",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    description: "This is the map",
  },
  {
    icon: BoltIcon,
    name: "Start Job",
    href: "/start",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    description: "Click here to start a job",
  },
  {
    icon: WrenchScrewdriverIcon,
    name: "Search",
    href: "/search",
    iconForeground: "text-red-700",
    iconBackground: "bg-red-50",
    description: "Click here to search all jobs",
  },
];

export default function Dashboard() {
  const nav = useNavigate();
  const { data: userData } = useUser();
  const { data } = useGetLatestJobs();
  const actions =
    userData?.user?.role === "user" ? userActions : technicianActions;
  const getJobsHeader = () => {
    return [
      { name: "Address", ref: "address" },
      { name: "Chemicals", ref: "chemicals" },
      { name: "Status", ref: "status" },
      { name: "Technician     ", ref: "technician" },
      { name: "Date", ref: "date" },
    ];
  };

  const getJobsData = () => {
    if (data?.jobs?.length < 0) return;
    const currentData = [];
    data?.jobs?.map((job) => {
      currentData.push({
        address: (
          <div className="flex items-center">
            <a
              className="hover:text-blue-500"
              href={`./job-details/${job?._id}`}
            >
              <p className="text-sm">{job?.address?.address}</p>
            </a>
          </div>
        ),
        Chemicals: (
          <div>
            {job.chemicals.map((chemical) => (
              <p key={chemical._id} className="text-sm">
                {chemical.chemicalName}
              </p>
            ))}
          </div>
        ),
        status: (
          <span
            className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 
                ${
                  job.active === true
                    ? "bg-blue-100 text-blue-800"
                    : "bg-green-100 text-green-800"
                }`}
          >
            {job.active === true ? "In-Progress" : "Completed"}
          </span>
        ),
        technician:
          (job?.user ? job?.user[0]?.username : job?.technician[0]?.username) ??
          job?.user?.username,
        date: dayjs(job.startTime).format("DD MMM YYYY"),
        actions: <></>,
      });
    });

    return currentData;
  };
  const tabledata = {
    headers: getJobsHeader(),
    tabledata: getJobsData(),
  };

  return (
    <>
      <DashboardLayout>
        <section className="m-5 gap-5 flex flex-col divide-y">
          <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-2">
            {actions.map((action) => (
              <div
                key={action.name}
                className="border rounded-lg shadow-sm cursor-pointer hover:scale-105 transition-all hover:shadow-md group bg-white"
                onClick={() => nav(action.href)}
              >
                <div className="m-3">
                  <div className="flex justify-between items-center">
                    <span className="w-5">
                      <action.icon
                        className={`${action.iconForeground} ${action.iconBackground} p-2 rounded-md`}
                      />
                    </span>
                    <span
                      className="text-gray-300 group-hover:text-gray-400 transition-all"
                      aria-hidden="true"
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                      </svg>
                    </span>
                  </div>
                  <div className="mt-3">
                    <h3 className="font-medium text-xl">{action.name}</h3>
                    <p className="mt-1 text-gray-500 text-sm">
                      {action.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="">
            <Table {...tabledata} />
            {data?.totalJobs > 5 && (
              <div className="flex items-center justify-center mt-4">
                <PrimaryButton
                  label="Show More"
                  onClickEvent={() => nav("/search")}
                  // displayPlus
                />
              </div>
            )}
          </div>
        </section>
      </DashboardLayout>
    </>
  );
}
