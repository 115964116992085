
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import jobDetailsReducer from "../features/jobDetailsSlice";
import jobInProgressReducer from "../features/jobInProgressSlice";
import modalReducer from "../features/modalSlice";

const rootReducer = combineReducers({
  jobDetails: jobDetailsReducer,
  jobInProgress: jobInProgressReducer,
  modal: modalReducer, 
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["jobDetails", "jobInProgress"], // only these reducers will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Needed for redux-persist
    }),
});

export const persistor = persistStore(store);
