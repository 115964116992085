import DashboardLayout from "../components/DashboardLayout";
import JobInProgress from "../components/Job/JobInProgress";
import JobCompleted from "../components/Job/JobCompleted";
import { useSelector } from "react-redux";

export default function Job() {
	const jobDetails = useSelector(state => state.jobDetails)
	return (
		<DashboardLayout>
			{ jobDetails.step === 3 ? 
				<JobCompleted /> 
				: 
				<JobInProgress /> }
		</DashboardLayout>
	)
}
