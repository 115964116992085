import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosProtected } from '../utils/axios-interceptor'
import { TimeDuration } from '../utils/calculateTime'

const createJob = async (job) => {
    const { data } = await axiosProtected.post(`${process.env.REACT_APP_IP}/jobs/createJob`, job)
    return data
}

const getAllUniqueJobs = async ({ queryKey }) => {
    const { selectedFilter, user, technician, hardwareId, startDate, endDate } = queryKey[1];
    let start, end;
    if (startDate && endDate) {
        start = startDate;
        end = endDate;
    } else {
        const timeRange = TimeDuration(selectedFilter);
        start = timeRange.startDate;
        end = timeRange.endDate;
    }

    try {
        const role = technician ? 'technician' : 'user';
        const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/activeJobs/${start || null}/${end || null}/${user || ''}/${hardwareId || null}/${role}`);
        return data;
    } catch (error) {
        throw new Error('Error fetching jobs');
    }
};

const getLatestJobs = async () => {
    try {
        const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/latestJobs`);
        return data;
    } catch (error) {
        throw new Error('Error fetching jobs');
    }
}


const endJob = async (id) => {
    const { data } = await axiosProtected.patch(`${process.env.REACT_APP_IP}/jobs/endJob`, id)
    return data
}

const getJobsOnProperty = async ({ queryKey }) => {
    const { propertyId, user } = queryKey[1]
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/jobsOnProperty`, { params: { propertyId, user } })
    return data
}

const getJobById = async ({ queryKey }) => {
    const jobId = queryKey[1]
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/jobById`, { params: { jobId } })
    return data
}

const isJobActive = async ({ queryKey }) => {
    const { address, user } = queryKey[1]
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/isJobActive`, { params: { address, user } })
    return data
}


const searchJobs = async ({ queryKey }) => {
    const { user, search, page, role, hwid, active, selectedFilter, technicianId, startDate, endDate } = queryKey[1];

    let start, end;
    if (startDate && endDate) {
      start = startDate;
      end = endDate;
    } else {
      const timeRange = TimeDuration(selectedFilter);
      start = timeRange.startDate;
      end = timeRange.endDate;
    }
  
    const { data } = await axiosProtected.get(
      `${process.env.REACT_APP_IP}/jobs/searchJobs`,
      {
        params: { user, search, page, role, active, hwid, startDate: start, endDate: end, technicianId }
      }
    );
    return data;
  };
  



export const useCreateJob = () => {
    return useMutation(createJob)
}

export const useGetAllUniqueJobs = ({ selectedFilter, user, technician, hardwareId, startDate, endDate }) => {
    return useQuery(
        ['filterByDate', { selectedFilter, user, technician, hardwareId, startDate, endDate }],
        getAllUniqueJobs,
        {
            enabled: Boolean(user),
            refetchOnWindowFocus: false,
            retry: 1,
            staleTime: 5 * 60 * 1000,
        }
    );
};

export const useEndJob = () => {
    return useMutation(endJob)
}

export const useGetJobsOnProperty = (query) => {
    return useQuery(['propertyJobList', query], getJobsOnProperty)
}

export const useGetJobById = (query) => {
    return useQuery(['JobById', query], getJobById)
}

export const useGetLatestJobs = (query) => {
    return useQuery(['LatestJobs', query], getLatestJobs)
}

export const useIsJobActive = (query) => {
    return useQuery(['isJobActive', query], isJobActive, {
        enabled: false
    })
}


export const useSearchJobs = (query, options = {}) => {
    return useQuery(['searchJob', query], searchJobs, {
      cacheTime: 5 * 60 * 1000, // 5 minutes
      ...options, // Spread any additional options
    });
  };