// client/src/utils/constants.js

export const tutorials = [
    {
      id: 1,
      title: 'React Basics',
      link: 'https://www.youtube.com/watch?v=gSSsZReIFRk',
      type: 'video', // 'video' or 'pdf'
      videoId: 'gSSsZReIFRk', // Extracted from the YouTube URL
    },
    {
      id: 2,
      title: 'Advanced React',
      link: 'https://reactjs.org/docs/hooks-intro.html',
      type: 'pdf',
      // No videoId needed for PDFs
    },
    {
      id: 3,
      title: 'Tailwind CSS',
      link: 'https://www.youtube.com/watch?v=UBOj6rqRUME',
      type: 'video',
      videoId: 'UBOj6rqRUME',
    },
    // Add more tutorials as needed
  ];
  