import { useCallback, useState } from "react";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { enUS } from 'date-fns/locale'; // Import the desired locale

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./DateFilter.css"
export default function DateFilter({
  dateRange,
  setDateRange,
  updateDateRange,
  setRecentSelection,
  setIsCalendarOpen,
}) {
  const [isLoading, setIsLoading] = useState(false); // Local loading state
  const [error, setError] = useState(""); // State for error messages

  const selectionRange = {
    // startDate: new Date(dateRange.startDate || addDays(new Date(), -30)),
    startDate: new Date(dateRange.startDate || (new Date())),
    endDate: new Date(dateRange.endDate || new Date()),
    key: 'selection'
  };

  const handleDateChange = useCallback((ranges) => {
    const { selection } = ranges;
    setDateRange({
      startDate: selection.startDate.toISOString(),
      endDate: selection.endDate.toISOString()
    });
  }, [setDateRange]);

  // Helper function to format date range
  const formatDateRange = (start, end) => {
    const formattedStart = new Date(start).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const formattedEnd = new Date(end).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    return `${formattedStart} - ${formattedEnd}`;
  };

  const handleDates = async () => {
    
    if (dateRange.startDate && dateRange.endDate) {
      // Validate that startDate is before endDate
      if (new Date(dateRange.startDate) > new Date(dateRange.endDate)) {
        setError("Start Date cannot be after End Date.");
        return;
      }

      setError(""); // Clear previous errors
      setIsLoading(true); // Start loading
      setRecentSelection(formatDateRange(dateRange.startDate, dateRange.endDate)); // Set to formatted range

      try {
        await updateDateRange(dateRange); // Ensure updateDateRange returns a Promise
        setIsCalendarOpen(false);
        localStorage.setItem("dateRange", JSON.stringify(dateRange));
      } catch (error) {
        console.error("Error updating date range:", error);
        setError("There was an error processing your request. Please try again.");
      } finally {
        setIsLoading(false); // End loading
      }
    } else {
      // Handle incomplete date range selection
      setError("Please select both start and end dates.");
    }
  };

  return (
    <div className="absolute z-50 w-[20rem] mt-2 bg-white shadow-lg rounded-md px-6 py-4 right-0">
      <div className="flex flex-col gap-3">
        <h1 className="font-medium text-md">Select Custom Date Range</h1>
        <p className="text-sm text-gray-700">
          All dates and time are presented in UTC. Usage data available for the last 18 months.
        </p>

        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleDateChange}
          editableDateInputs={true}
          showDateDisplay={false}
          months={1}
          direction="vertical" 
          rangeColors={["#3d91ff"]} 
          minDate={addDays(new Date(), -365 * 1.5)} // Limit date to last 18 months
          maxDate={new Date(2033, 11, 31)} // Set max date to Dec 31, 2033
          shownDate={new Date()} // Ensure the calendar initially shows today’s date
          locale={enUS}
          moveRangeOnFirstSelection={false}
        />
        {/* Display Error Message if Any */}
        {error && <p className="text-red-500 text-sm">{error}</p>}

        <div className="w-full flex justify-between">
       
        <button
          className={`flex items-center justify-center bg-blue-700 text-center text-sm font-medium mt-4 py-2 px-3 rounded-lg mb-1 w-20 text-white cursor-pointer ${
            isLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleDates}
          disabled={isLoading} // Disable button while loading
        >
          Apply
        </button>
        </div>
      </div>
    </div>
  );
}


