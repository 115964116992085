import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import GoogleAutocompleteInput from "../components/Start/GoogleAutocompleteInput";
import { setChemicals, setHardwares, setStep } from "../features/jobDetailsSlice";
import { setJobInProgress } from "../features/jobInProgressSlice";
import { useUser } from "../hooks/useAuth";
import { useFetchChemicals } from "../hooks/useChemicals";
import { useFetchHardwares } from "../hooks/useHardware";
import { useCreateJob, useSearchJobs } from "../hooks/useJobs";
import { useCreateProperty } from "../hooks/useProperty";
import DropdownMenuHC from "../components/Menus/DropdownHC";

// Keeping libraries array as a const outside component due to performance warning in console
const libraries = ["places"];

export default function Start() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [selectedChemicals, setSelectedChemicals] = useState([]);
  const [selectedHardwares, setSelectedHardwares] = useState([]);
  const jobDetails = useSelector((state) => state.jobDetails);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const { mutateAsync: createJob } = useCreateJob();
  const { mutateAsync: createProperty } = useCreateProperty();
  const { data: userData } = useUser();
  const { data: chemicals } = useFetchChemicals();
  const { data: hardwares } = useFetchHardwares();
  const { data, refetch: refetchCurrentJobs } = useSearchJobs({
    page: 1,
    search: "",
    user:
      userData?.user?.role === "user"
        ? userData?.user?._id
        : userData?.user?.ownerUserId,
    technician:
      userData?.user?.role === "technician" ? userData?.user?._id : null,
    completeUser: userData?.user,
    role: userData?.user?.role,
    active: true,
  });
  const date = new Date();
 

  useEffect(() => {
    refetchCurrentJobs();
    if (!data?.jobs?.length) return;
    const { jobs } = data;
    const currentOpenJob = jobs[0];
    dispatch(setJobInProgress(currentOpenJob));
    nav("/job");
  }, [userData, data]);

  const onClickStartJob = async () => {
    const property = await createProperty({
      address: jobDetails.address,
      mapCenter: jobDetails.mapCenter,
    });
    const res = await createJob({
      address: property._id,
      chemicals: jobDetails.chemicals,
      hardwares: jobDetails.hardwares,
      ownerUserId:
        userData?.user.role === "user"
          ? userData?.user?._id
          : userData?.user?.ownerUserId,
      startTime: date,
      endTime: null,
      technician:
        userData?.user.role === "technician" ? userData?.user?._id : null,
    });
    dispatch(setJobInProgress(res));
    dispatch(setStep(2))
    nav("/job");
  };

  const chemicalOptions = chemicals?.map((item) => ({
    id: item._id,
    name: item.chemicalName,
  }));

  const hardwareOptions = hardwares?.map((item) => ({
    id: item._id,

    name: item.name, //displays hardware name in dropdown on start job route
  }));

  useEffect(() => {
    dispatch(setChemicals(selectedChemicals));
  }, [selectedChemicals, dispatch]);

  useEffect(() => {
    dispatch(setHardwares(selectedHardwares));
  }, [selectedHardwares, dispatch]);

  const DropdownWithSelectionBox = ({
    dropdownOptions,
    selectedItems,
    setSelectedItems,
    placeholder,
    title,
    classNames,
    dropdownPosition,
    itemName,
  }) => {
    return (
      <div
      
        className={`w-full flex flex-col ${
          dropdownPosition === "right"
            ? "md:items-end lg:items-end"
            : "md:items-start lg:items-start"
        }`}
      >
        <DropdownMenuHC
          dropdownOptions={dropdownOptions}
          selector={selectedItems}
          setSelector={setSelectedItems}
          arrowDirectionUp={false}
          dropdownPosition={dropdownPosition}
          type="checkbox"
          placeholder={placeholder}
          classNames={classNames}
          itemName={itemName}
        />
        <div className="w-full sm:w-[100%] md:w-[90%] flex flex-col border rounded-t-xl py-4 mt-4 divide-y overflow-hidden">
          <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">
            {title}
          </h1>
          <div className="p-2 flex flex-col gap-2 overflow-y-auto max-h-[300px] h-[200px]">
            {selectedItems.length > 0 ? (
              selectedItems.map((item, index) => (
                <div
                  key={index}
                  className="text-md text-blue-500 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2"
                >
                  {dropdownOptions.find((option) => option.id === item)?.name ||
                    item}
                </div>
              ))
            ) : (
              <p className="text-gray-500 text-sm text-center">
                No items selected
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <div className="mt-10">{isLoaded && <GoogleAutocompleteInput />}</div>

      {jobDetails.step === 2 && (
        <>
          <h1 className="text-3xl text-center py-6 font-medium text-blue-600 pb-4">
            Chemicals & Hardwares
          </h1>
          <div className="w-full flex flex-col md:flex-row p-5 gap-4">
            <DropdownWithSelectionBox
              dropdownOptions={hardwareOptions}
              selectedItems={selectedHardwares}
              setSelectedItems={setSelectedHardwares}
              placeholder="Choose Single or Multiple Hardwares"
              title="Selected Hardwares"
              classNames=""
              dropdownPosition="left"
            />

            <DropdownWithSelectionBox
              dropdownOptions={chemicalOptions}
              selectedItems={selectedChemicals}
              setSelectedItems={setSelectedChemicals}
              placeholder="Choose Single or Multiple Chemicals"
              title="Selected Chemicals"
              classNames=""
              dropdownPosition="right"
              itemName="Chemicals"
            />
          </div>
          {jobDetails.chemicals.length > 0 &&
            jobDetails.hardwares.length > 0 && (
              <div className="flex w-full justify-center py-2">
                <button
                  className="bg-blue-600 text-white px-5 py-3 text-xl text-center rounded-md w-60 hover:bg-blue-800 transition-all tracking-wider"
                  onClick={onClickStartJob}
                >
                  START JOB
                </button>
              </div>
            )}
        </>
      )}
    </DashboardLayout>
  );
}
