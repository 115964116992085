import React from 'react';
import { PlayCircleIcon, DocumentTextIcon } from '@heroicons/react/24/solid';

const TutorialCard = ({ title, link, type, videoId, openModal }) => {
  const isVideo = type === 'video';
  const IconComponent = isVideo ? PlayCircleIcon : DocumentTextIcon;
  const bgColor = 'bg-gray-100';
  const iconColor = 'text-gray-700';

  const handleIconClick = () => {
    if (isVideo && videoId) {
      openModal(videoId); // Trigger modal via context
    } else {
      window.open(link, '_blank'); // Open PDF in new tab
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
      {/* Icon Section */}
      <div
        className={`${bgColor} flex items-center justify-center h-48 cursor-pointer`}
        onClick={handleIconClick}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleIconClick();
          }
        }}
        aria-label={`${isVideo ? 'Play video' : 'Open PDF'} tutorial: ${title}`}
      >
        <IconComponent className={`h-16 w-16 ${iconColor}`} />
      </div>

      {/* Content Section */}
      <div className="p-4 ">
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        {isVideo ? (
          // If it's a video, render a button that opens the modal
          <button
            onClick={handleIconClick}
            className="text-blue-500 border border-gray-500 rounded-md px-2 py-1 transform
              transition-transform
              duration-300
              hover:scale-105 focus:outline-none"
            aria-label={`Play video tutorial: ${title}`}
          >
            Play Video
          </button>
        ) : (
          // If it's an article, render a standard link
          <button className='text-blue-500 border border-gray-500 rounded-md px-2 py-1 transform
              transition-transform
              duration-300
              hover:scale-105 focus:outline-none '>
          <a
            href={link}
            className="text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Article
          </a>
          </button>
        )}
      </div>
    </div>
  );
};

export default TutorialCard;
