import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Protected from "./components/Protected";
import Technicians from "./components/Technicians/Technicians";
import AcceptInvitation from "./pages/AcceptInvitation";
import AdminDashboard from "./pages/adminDashboard.jsx";
import Chemicals from "./pages/chemicals";
import Dashboard from "./pages/dashboard";
import { Hardware } from "./pages/hardware";
import Job from "./pages/job";
import JobDetails from "./pages/job-details/[id].jsx";
import MapView from "./pages/mapview.jsx";
import MyAccount from "./pages/myaccount";
import NoPage from "./pages/nopage";
import Profile from "./pages/Profile";
import Search from "./pages/search";
import Signin from "./pages/signin";
import Start from "./pages/start.jsx";
import OnlyAdmin from "./components/OnlyAdmin.jsx";
import AdminMapView from "./pages/adminMapView.jsx"
import OtaUpdates from "./pages/OtaUpdates.jsx";
import Versions from "./pages/Versions.jsx";
import Tutorials from "./pages/Tutorials.jsx";

const App = () => {

  const reteiveUserFromLocalStorage = () => {
    const user = localStorage.token && JSON.parse(atob(localStorage.token.split('.')[1]));
    return user?.role
  }

  const redirectToDashboard = (path) => {
    if (reteiveUserFromLocalStorage() === "technician") {
     return <Route path={path} element={ <Navigate to="/dashboard" /> } />
    }
    
    if(reteiveUserFromLocalStorage() === "admin"){
      return <Route path={path} element={ <Navigate to="/admin/dashboard" /> } />
    }
  }

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            localStorage.token ? (
              <Navigate replace to='/dashboard' />
            ) : (<Navigate replace to='/signin' />)
          } 
          />
          <Route path="signin" element={
            localStorage.token?reteiveUserFromLocalStorage()==='admin' ? (
              <Navigate replace to={'/admin/dashboard'} />
            ):(
              <Navigate replace to={'/dashboard'} />
            ) : (<Signin />)
          } />
          <Route path="mapview" element={<Protected isLoggedIn={localStorage.token}><MapView /></Protected>} />
          <Route path="/adminMap" element={<OnlyAdmin  role={reteiveUserFromLocalStorage()}><AdminMapView /></OnlyAdmin >} />
          <Route path="myaccount" element={<Protected isLoggedIn={localStorage.token}><MyAccount /></Protected>} />
          <Route path="profile" element={<Protected isLoggedIn={localStorage.token}><Profile /></Protected>} />
          <Route path="technicians" element={<Protected isLoggedIn={localStorage.token}><Technicians /></Protected>} />
          <Route path="search" element={<Protected isLoggedIn={localStorage.token}><Search /></Protected>} />
          {
            redirectToDashboard("chemicals")
          }
          <Route path="chemicals" element={<Protected isLoggedIn={localStorage.token}><Chemicals /></Protected>} />
          <Route path="dashboard" element={<Protected isLoggedIn={localStorage.token}><Dashboard /></Protected>} />
          <Route path="/admin/dashboard" element={<OnlyAdmin role={reteiveUserFromLocalStorage()}><AdminDashboard /></OnlyAdmin>} />
          <Route path="/admin/updates" element={<OnlyAdmin role={reteiveUserFromLocalStorage()}><OtaUpdates/></OnlyAdmin>} />
          <Route path="/admin/versions" element={<OnlyAdmin role={reteiveUserFromLocalStorage()}><Versions/></OnlyAdmin>} />
          <Route path="start" element={<Protected isLoggedIn={localStorage.token}><Start /></Protected>} />
          <Route path="job" element={<Protected isLoggedIn={localStorage.token}><Job /></Protected>} />
          <Route path="/job-details/:id" element={<Protected isLoggedIn={localStorage.token}><JobDetails /></Protected>} />
          <Route path="/invitation/accept-invitation/:email" element={<AcceptInvitation />} />
          {
            redirectToDashboard("hardware")
          }
          <Route path="hardware" element={<Protected isLoggedIn={localStorage.token}><Hardware /></Protected>} />
          <Route path="*" element={<NoPage />} />
          <Route path="/tutorials" element={<Protected isLoggedIn={localStorage.token}><Tutorials /></Protected>} />

        </Routes>
      </BrowserRouter>
  );
}

export default App;
