import { Menu } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import react-router-dom for query params
import { mergeClasses } from "../../utils/helpers";
import DateFilter from "../FilterComponents/DateFilter";

// Helper function to parse query parameters
const getQueryParams = (search) => {
  const params = new URLSearchParams(search);
  return {
    startDate: params.get("startDate") || "",
    endDate: params.get("endDate") || "",
  };
};

export default function DropdownMenu({
  label,
  dropdownPosition,
  calender = true, // Ensure this is set to true to use the calendar
  updateDateRange,
  classNames = "",
  onReset = () => {},
}) {
  const location = useLocation(); // Get location object for reading query params
  const navigate = useNavigate();  // For updating query params

  // Parse initial query parameters
  const { startDate: initialStartDate, endDate: initialEndDate } = getQueryParams(location.search);

  // State for selected date range
  const [recentSelection, setRecentSelection] = useState("Today");
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: initialStartDate, endDate: initialEndDate });

  const defaultButtonStyles = `inline-flex w-full justify-between text-sm text-gray-900 ${
    calender ? "pl-4" : "px-4"
  } py-2.5 cursor-pointer gap-6`;
  const menuButtonClasses = mergeClasses(defaultButtonStyles, classNames);

  // Helper function to format date range
  const formatDateRange = (start, end) => {
    const formattedStart = dayjs(start).format("MMM D, YYYY");
    const formattedEnd = dayjs(end).format("MMM D, YYYY");
    return `${formattedStart} - ${formattedEnd}`;
  };

  // Update query parameters
  const updateQueryParams = (newParams) => {
    const params = new URLSearchParams(location.search);

    Object.keys(newParams).forEach((key) => {
      const value = newParams[key];
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });

    navigate({
      pathname: location.pathname,
      search: params.toString(),
    }, { replace: true });
  };

  // Update query params when dateRange changes
  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      setRecentSelection(formatDateRange(dateRange.startDate, dateRange.endDate));

      // Update the query parameters
      updateQueryParams({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    } else {
    setRecentSelection("Today");

      updateQueryParams({ startDate: null, endDate: null });
    }
  }, [dateRange]);

  const resetSelections = () => {
    setDateRange({ startDate: "", endDate: "" });
    setRecentSelection("Today");


    // Remove query parameters
    updateQueryParams({ startDate: null, endDate: null });
  };

  // Handle reset externally if onReset is provided
  useEffect(() => {
    if (typeof onReset === "function") {
      onReset(resetSelections);
    }
  }, [onReset]);

  const handleDropdownClick = useCallback((e) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev);
    setIsCalendarOpen(false); // Close calendar when toggling dropdown
  }, []);

  const handleCalendarClick = useCallback((e) => {
    e.preventDefault();
    setIsCalendarOpen((prev) => !prev);
    setIsDropdownOpen(false); // Close dropdown when toggling calendar
  }, []);

  const CalenderButton = () => {
    if (!calender) return null;

    return (
      <div
        onClick={handleCalendarClick}
        className="ml-2 rounded-r-md cursor-pointer px-2 py-2.5 bg-gray-100 hover:bg-gray-200"
      >
        <CalendarIcon className="h-5 w-5 text-gray-600 " />
      </div>
    );
  };

  const RenderDropdownMenu = () => {
    return (
      <Menu as="div" className="relative inline-block">
        <div className="flex w-full justify-between items-center rounded-md bg-white text-sm text-gray-900 border border-gray-300">
          <Menu.Button
            className={menuButtonClasses}
            onClick={handleCalendarClick}
            style={{ width: "100%" }}
          >
            <div className="text-[14px] flex-grow-1 whitespace-nowrap flex w-full justify-between">
              <span>{recentSelection}</span>
            </div>
          </Menu.Button>

          <CalenderButton />
        </div>
      </Menu>
    );
  };

  return (
    <div className="w-full flex">
      <div className="relative w-full inline-block text-left">
        <div>
          <span className="flex justify-start items-center text-[14px] mb-0">
            {label}
          </span>

          <div
            className={`flex ${
              dropdownPosition === "right" ? "md:justify-end lg:justify-end" : ""
            }`}
          >
            <RenderDropdownMenu />
          </div>
        </div>

        {/* Calendar Dropdown */}
        {calender && isCalendarOpen && (
          <DateFilter
            dateRange={dateRange}
            setDateRange={setDateRange}
            updateDateRange={updateDateRange}
            setRecentSelection={setRecentSelection}
            setIsCalendarOpen={setIsCalendarOpen}
          />
        )}
      </div>
    </div>
  );
}






