import {
  CheckCircleIcon,
  ClockIcon,
  CogIcon,
} from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/20/solid';
import { formatDate } from '../../utils/formateDate';
import { useDispatch, useSelector } from 'react-redux';
import { flushjobDetails, setStep } from '../../features/jobDetailsSlice';
import { setJobInProgress } from '../../features/jobInProgressSlice';
import { useNavigate } from 'react-router-dom';

export default function JobCompleted() {
  const jobInProgress = useSelector(
    (state) => state.jobInProgress.jobInProgressDoc
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleStartAnotherJob = () => {
    // Reset the Redux state
    dispatch(setStep(1));
    dispatch(setJobInProgress({}));
    dispatch(flushjobDetails());

    // Navigate to the '/start' route
    navigate('/start');
  };

  const cards = [
    {
      title: 'Start Time',
      icon: ClockIcon,
      time: formatDate(jobInProgress.startTime),
    },
    {
      title: 'Time Spent',
      icon: CogIcon,
      time:
        jobInProgress.jobDuration === '  '
          ? 'less than a minute'
          : jobInProgress.jobDuration,
    },
    {
      title: 'Stop Time',
      icon: CheckCircleIcon,
      time: formatDate(jobInProgress.endTime),
    },
  ];

  const TotalSection = () => {
    if (!jobInProgress?.meta) return null;
    const { meta } = jobInProgress;

    return (
      <section className='my-10'>
        <p className='font-medium text-2xl'>Total Summary:</p>

        <div className='mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3'>
          <div className='flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-2'>
            <div className='p-6 flex flex-col gap-3'>
              <div className='flex flex-col gap-2'>
                <h1 className='text-xl'>Total</h1>
                <div className='text-sm flex items-center gap-1'>
                  <CheckIcon
                    className='h-5 w-5 flex-shrink-0 text-blue-500'
                    aria-hidden='true'
                  />
                  <span className='font-medium'>Amount:</span>{' '}
                  {meta?.totalSprayUsed.toFixed(2)} gal
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <div className='block m-5'>
      <p className='text-3xl font-semibold text-center'>
        You have just completed a job
      </p>
      <br />
      <br />
      <div className='mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
        {cards.map((card) => (
          <div
            key={card.title}
            className='overflow-hidden rounded-lg bg-white shadow-sm border border-gray-300'
          >
            <div className='p-5'>
              <div className='flex items-center'>
                <div>
                  <dl>
                    <div className='flex items-center gap-x-1'>
                      <div>
                        <card.icon
                          className='h-6 w-6 text-blue-600'
                          aria-hidden='true'
                        />
                      </div>
                      <dt className='truncate text-sm font-medium text-blue-600'>
                        {card.title}
                      </dt>
                    </div>
                    <dd>
                      <p className='text-lg font-medium text-gray-900 mt-1 ml-1'>
                        {card.time}
                      </p>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <TotalSection />

      <section className='my-10'>
        <p className='font-medium text-2xl'>Chemicals used:</p>

        <div className='mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3'>
          {jobInProgress.chemicals?.map((chemical) => (
            <div
              key={chemical._id}
              className='flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-6'
            >
              <div className='p-6 '>
                <h3 className='text-xl'>{chemical.chemicalName}</h3>
                <ul className='mt-6 space-y-4'>
                  <li className='flex space-x-3'>
                    <CheckIcon
                      className='h-5 w-5 flex-shrink-0 text-blue-500'
                      aria-hidden='true'
                    />
                    <span className='text-sm text-gray-500'>
                      Amount Used:{' '}
                      {jobInProgress?.meta?.chemicalsData
                        ?.find(
                          (data) => data.chemicalName === chemical.chemicalName
                        )
                        ?.amountUsed.toFixed(2)}
                    </span>
                  </li>
                  <li className='flex space-x-3'>
                    <CheckIcon
                      className='h-5 w-5 flex-shrink-0 text-blue-500'
                      aria-hidden='true'
                    />
                    <span className='text-sm text-gray-500'>
                      Concentration: {chemical.concentration}{' '}
                      {chemical.measurement}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className='my-10'>
        <p className='font-medium text-2xl'>Hardware used:</p>

        <div className='mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3'>
          {jobInProgress?.hardwares?.map((singleHardware) => (
            <div
              key={singleHardware._id}
              className='flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-6'
            >
              <div className='p-6 '>
                <h3 className='text-xl'>{singleHardware.name}</h3>
                <ul className='mt-6 space-y-4'>
                  <li className='flex space-x-3'>
                    <CheckIcon
                      className='h-5 w-5 flex-shrink-0 text-blue-500'
                      aria-hidden='true'
                    />
                    <span className='text-sm text-gray-500'>
                      Hardware ID: {singleHardware.hwid}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>

      <div className='flex justify-center'>
        <button
          onClick={handleStartAnotherJob}
          className='bg-blue-500 text-white px-5 py-3 text-xl text-center rounded-md w-60 hover:bg-blue-600 transition-all tracking-wider'
        >
          Start another Job
        </button>
      </div>
    </div>
  );
}
