import React, { useEffect, useState, useMemo } from "react";
import {
  CheckIcon,
  ArrowPathIcon,
  ArrowLeftIcon,
} from "@heroicons/react/20/solid";
import { useGetJobsOnProperty } from "../../hooks/useJobs";
import dayjs from "dayjs";
import { ErrorAlert } from "../Popups/ErrorAlert";
import { useUser } from "../../hooks/useAuth";
import { Loader } from "../Loader/Loader";
import { getDateRangeForFilter } from "../../utils/dataRange";
import { useLocation } from "react-router-dom"; // Import useLocation

export default function JobsTimeline({ propertyId, setStep, setJobId }) {
  const { data: userData } = useUser();
  const location = useLocation(); // Use useLocation hook to get the query params
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  // Function to parse query params
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      startDate: params.get("startDate") || "",
      endDate: params.get("endDate") || "",
      filter: params.get("filter") || null,
    };
  };

  useEffect(() => {
    // Parse query params to get the date range
    const { startDate, endDate } = getQueryParams(location.search);
    if (startDate && endDate) {
      setDateRange({ startDate, endDate });
    }
  }, [location.search]); // Listen to changes in the URL query parameters

  const { data, isLoading, isError, error } = useGetJobsOnProperty({
    propertyId,
    user: userData?.user?._id,
  });

  // Check if filters are applied
  const isFilterApplied = useMemo(() => {
    return dateRange.startDate || dateRange.endDate;
  }, [dateRange]);

  // Filter jobs based on the date range from query params
  const filteredJobs = useMemo(() => {
    if (!data || !isFilterApplied) return data; // If no filters, return all jobs

    const start = dayjs(dateRange.startDate);
    const end = dayjs(dateRange.endDate);

    return data.filter((job) => {
      const jobDate = dayjs(job.startTime); // Adjust based on your requirement
      return jobDate.isAfter(start) && jobDate.isBefore(end);
    });
  }, [data, dateRange, isFilterApplied]);

  const handleBack = () => {
    setStep(1);
  };

  const handleJobDetails = (jobId) => {
    setJobId(jobId);
    setStep(3);
  };

  if (isLoading) return <Loader />;
  if (isError) return <ErrorAlert errorMessages={error.message} />;

  return (
    <div className="mt-6 flow-root">
      <div className="px-4 py-3 sm:rounded-lg sm:px-6">
        <div className="mt-[-20px]">
          <button
            onClick={handleBack}
            type="button"
            className=" text-blue-700 font-medium rounded-full hover:bg-blue-100 bg-blue-50 text-sm p-2.5 text-center inline-flex items-center"
          >
            <ArrowLeftIcon className="w-6 h-6" />
          </button>
        </div>
        <ul className="mt-2">
          <li>
            <h2
              id="timeline-title"
              className="text-lg font-medium text-gray-900 mt-2"
            >
              {filteredJobs[0]?.address.address}
            </h2>
          </li>
          {filteredJobs.map((job, jobIdx) => (
            <li key={job.id}>
              <div className="relative pb-8">
                {jobIdx !== filteredJobs.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex items-center space-x-3">
                  <div>
                    <span
                      className={`mt-2 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white 
                            ${
                              job.active === false
                                ? "bg-blue-500"
                                : "bg-purple-500"
                            }`}
                    >
                      {job.active === false ? (
                        <CheckIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowPathIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      {job.active === false ? (
                        <button
                          onClick={() => handleJobDetails(job._id)}
                          className="font-medium text-left text-gray-900 hover:text-gray-500"
                        >
                          Job completed at {dayjs(job.endTime).format("DD MMM")}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleJobDetails(job._id)}
                          className="font-medium text-gray-900 hover:text-gray-500"
                        >
                          Job started at{" "}
                          <span className="text-gray-600">
                            {dayjs(job.startTime).format("DD MMM")}
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500"></div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
