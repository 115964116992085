
import React from 'react';
import DashboardLayout from '../components/DashboardLayout';
import TutorialCard from '../components/tutorial-cards/Tutorial-Card';
import { tutorials } from './tutorials-const';
import { useDispatch } from 'react-redux';
import { openModal } from '../features/modalSlice'; 

const Tutorials = () => {
  const dispatch = useDispatch();

  const handleOpenModal = (videoId) => {
    dispatch(openModal(videoId)); 
  };

  return (
    <DashboardLayout>
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-6">Tutorials</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {tutorials.map((tutorial) => (
            <TutorialCard
              key={tutorial.id}
              title={tutorial.title}
              link={tutorial.link}
              type={tutorial.type}
              videoId={tutorial.videoId}
              openModal={handleOpenModal} 
            />
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Tutorials;
